<template>
  <div class="tab-list">
    <div class="tab-item" v-for="(item, index) in dataList" :key="index" @click="clickTabItem(item)">
      <div :class="[tabActive === item.key ? 'active-name' : 'name']">{{ item.name }}</div>
      <div class="line" v-if="tabActive === item.key"></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'MobileTabs',
    data() {
      return {
        dataList: []
      }
    },
    props: {
      tabActive: {
        type: [String, Number],
        default: ''
      },
      tabList: {
        type: Array,
        default: []
      }
    },
    watch: {
      tabList: {
        handler(newVal, oldVal) {
          this.dataList = this.$utils.deepClone(newVal)
        }
      }
    },

    mounted() {
      this.dataList = this.$utils.deepClone(this.tabList)
    },

    methods: {
      clickTabItem(item) {
        this.$emit('tabClick', item)
      }
    }
  }
</script>
<style lang="scss" scoped>
  .tab-list {
    width: 100%;
    height: 70px;
    background-color: #FFF;
    overflow-x: auto;
    white-space: nowrap;
    text-align: center;
    border-bottom: 2px solid #E0E0E0;
    .tab-item {
      position: relative;
      display: inline-block;
      height: 68px;
      margin: 0 30px;
      text-align: center;
      line-height: 68px;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      .line {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        border-radius: 4px;
        background-color: #00A6B9;
      }
      .active-name {
        color: #00A6B9;
      }
      .name {
        color: #333;
      }
    }
  }
</style>