<template>
  <div class="pc-top-nav" @mouseleave="showChild = false">
    <div class="top-nav" :style="{ background: background, color: color }">
      <div class="logo-box" @click="clickLogo">
        <img class="logo" v-if="$route.path.includes('product')" src="@/assets/pc_product_logo.png" alt="" />
        <img class="logo" v-else-if="showBlackLogo" src="@/assets/pc_black_logo.png" alt="" />
        <img class="logo" v-else src="@/assets/pc_logo.png" alt="" />
      </div>
      <div class="menu-list">
        <div
          :class="['menu-item', (toPath ? toPath.includes(item.activePath) : $route.path.includes(item.activePath)) ? 'menu-active' : '']"
          v-for="(item, index) in menuList"
          :key="index"
          @click="clickMenuItem(item)"
          @mouseenter="menuHover(item)"
        >
          {{ item.name }}
          <!-- <div v-if="$route.path === item.path" class="line"></div> -->
        </div>
      </div>
      <div class="phone" :style="{ color: color }">
        <img v-if="color === '#FFF'" class="phone-icon" src="@/assets/icons/pcIcons/icon_top_phone.png" alt="" />
        <img v-else class="phone-icon" src="@/assets/icons/pcIcons/icon_top_phone1.png" alt="" />
        027-86775880
      </div>
      <div class="top-drop">
        <el-dropdown trigger="click">
          <div class="el-dropdown-link">
            <img v-if="color === '#FFF'" width="16px" height="16px" src="@/assets/icons/pcIcons/icon_top_web1.png" alt="" />
            <img v-else width="16px" height="16px" src="@/assets/icons/pcIcons/icon_top_web.png" alt="" />
            <div class="text" :style="{ color: color === '#FFF' ? '#FFF' : '#999' }">语言</div>
            <el-icon color="#E0E0E0">
              <arrow-down />
            </el-icon>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="(item, index) in languageList"
                :key="index"
                @click="clickDropItem(item.yzValue)"
              >{{ item.yzName }}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <div class="top-line"></div>
        <el-dropdown trigger="click">
          <div class="el-dropdown-link">
            <div class="text" :style="{ color: color === '#FFF' ? '#FFF' : '#999' }">其他网站</div>
            <el-icon color="#E0E0E0">
              <arrow-down />
            </el-icon>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="(item, index) in webList"
                :key="index"
                @click="clickDropItem(item.yzValue)"
              >{{ item.yzName }}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="showChild && productMenuConfig[hoverPath]"
        :class="['child-menu', $route.path === '/index' ? 'black-bg' : '']"
        :style="{ justifyContent: productMenuConfig[hoverPath].length >= 12 ? 'left' : 'center' }"
      >
        <div
          v-for="(item, index) in productMenuConfig[hoverPath]"
          :key="index"
          :class="['child-item', activeChild === item.classifyId ? 'active-item' : '']"
          @click="clickChild(item)"
        >
          {{ item.classifyName }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import { useStore } from 'vuex'
  import api_common from '@/api/common'
  export default {
    name: 'PcTopNav',
    data() {
      return {
        activeMenu: '',
        menuList: [],
        productMenuConfig: {},
        showChild: false,
        hoverPath: '',
        store: useStore(),
        activeChild: '',
        languageList: [],
        webList: []
      }
    },
    props: {
      color: {
        type: String,
        default: '#FFF'
      },
      background: {
        type: String,
        default: 'rgba(0, 0, 0, 0.5)'
      },
      showBlackLogo: {
        type: Boolean,
        default: true
      },
      toPath: {
        type: String,
        default: ''
      }
    },

    mounted() {
      this.menuList = this.$commonData.menuList
      this.productMenu = this.$commonData.productMenuList
      if (this.store.state.nowClassifyId) {
        this.activeChild = this.store.state.nowClassifyId
      }
      this.store.watch(
        (state) => state.nowClassifyId,
        (newVal, oldVal) => {
          this.activeChild = newVal
        }
      )
      this.getClassList()
      this.getWebList('语言', 'languageList')
      this.getWebList('网站', 'webList')
    },

    methods: {
      clickDropItem(value) {
        window.open(value)
      },
      getWebList(yzType, dataName) {
        const params = { yzType }
        api_common.fetchWebList(params).then(res => {
          if (res.code !== 200) return
          this[dataName] = res.rows
        })
      },
      getClassList() {
        const params = {
          classifyType: 6 // 后端写死的
        }
        api_common.fetchClassList(params).then(res => {
          if (res.code !== 200) return
          res.rows.forEach(item => {
            if (item.classifyName === '产品中心') {
              this.getProductChild(item.classifyId, 'getCenterChildConfig', '/productCenter')
            }
            if (item.classifyName === '参数分类') {
              this.getProductChild(item.classifyId, 'getParamsChildConfig', '/parameterClass')
            }
            if (item.classifyName === '应用领域') {
              this.getProductChild(item.classifyId, 'getAppChildConfig', '/appArea')
            }
          })
        })
      },
      getProductChild(parentId, storeMethods, keyName) {
        const params = {
          parentId
        }
        api_common.fetchClassifyChild(params).then(res => {
          if (res.code !== 200) return
          const result = res.data.map(item => {
            return {
              classifyId: item.classifyId,
              classifyName: item.classifyName,
              classifyType: item.classifyType,
              createTime: item.createTime
            }
          })
          this.store.commit(storeMethods, result)
          this.productMenuConfig[keyName] = result
        })
      },
      clickMenuItem(item) {
        this.store.commit('getNowClassifyId', '')
        this.$router.push({
          path: item.path
        })
      },
      menuHover(item) {
        this.hoverPath = item.path
        if (item.name === '产品中心' || item.name === '参数分类' || item.name === '应用领域') {
          this.showChild = true
        } else {
          this.showChild = false
        }
      },
      clickLogo() {
        this.$router.push({
          path: '/index'
        })
      },
      clickChild(item) {
        this.activeChild = item.classifyId
        this.store.commit('getNowClassifyId', item.classifyId)
        this.$router.push({
          path: this.hoverPath
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  .pc-top-nav {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    box-sizing: border-box;
    .top-nav {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 110px;
      padding: 0 160px;
      .logo-box {
        cursor: pointer;
        .logo {
          width: 238px;
          height: 54px;
        }
      }
      .menu-list {
        display: flex;
        align-items: center;
        height: 100%;
        margin: 0 117px 0 92px;
        .menu-item {
          position: relative;
          display: flex;
          align-items: center;
          height: 100%;
          padding: 0 20px;
          font-size: 18px;
          cursor: pointer;
          transition: all .3s linear;
          &:hover {
            color: #FFF;
            background-color: #34b5c6;
          }
          .line {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 4px;
            border-radius: 2px;
            background-color: #00DDBF;
            animation: scaleAnimation .3s linear;
          }
        }
        .menu-active {
          // color: #00DDBF;
          color: #FFF;
          background-color: #34b5c6;
          transition: all .3s linear;
        }
      }
      .phone {
        display: flex;
        align-items: center;
        .phone-icon {
          width: 25px;
          height: 24px;
          margin-right: 10px;
        }
        font-size: 24px;
        font-weight: 700;
        color: #FFF
      }
      .top-drop {
        position: absolute;
        top: 7px;
        right: 160px;
        display: flex;
        align-items: center;
        .el-dropdown-link {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #999;
          cursor: pointer;
          .text {
            margin: 0 12px 0 6px;
          }
        }
        .top-line {
          width: 2px;
          height: 12px;
          margin: 0 30px;
          background-color: #E0E0E0;
        }
      }
    }
    .child-menu {
      width: 100%;
      height: 80px;
      display: flex;
      // justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, .8);
      box-sizing: border-box;
      overflow-x: auto; /* 自动显示水平滚动条 */
      white-space: nowrap; /* 防止内容换行 */
      .child-item {
        min-width: 130px;
        font-size: 16px;
        text-align: center;
        // padding: 0 20px;
        cursor: pointer;
      }
      .active-item {
        color: #34b5c6;
      }
    }
    .black-bg {
      color: #FFF;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
  /* 定义过渡效果 */
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }
  @keyframes scaleAnimation {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    background-color: #b4b4b4;
    cursor: pointer;
    &:hover {
      background-color: #b3b2b2;
    }
  }
  ::-webkit-scrollbar-track {
    background-color: transparent ;
  }
</style>
