export const pcRouter = [
  {
    path: '/index', // 首页
    name: 'PcIndex',
    component: () => import('../views/pcPages/index.vue'),
    meta: { type: 'pc' }
  },
  {
    path: '/indexAgent', // 代理商中心
    name: 'PcIndexAgent',
    component: () => import('../views/pcPages/indexAgent.vue'),
    meta: { type: 'pc' }
  },
  {
    path: '/aboutUs', // 关于我们
    name: 'PcAboutUs',
    component: () => import('../views/pcPages/aboutUs.vue'),
    meta: { type: 'pc' }
  },
  {
    path: '/productCenter', // 产品中心
    name: 'PcProductCenter',
    component: () => import('../views/pcPages/productCenter.vue'),
    meta: { type: 'pc' }
  },
  {
    path: '/productDetail', // 产品详情
    name: 'PcProductDetail',
    component: () => import('../views/pcPages/productDetail.vue'),
    meta: { type: 'pc' }
  },
  {
    path: '/parameterClass', // 参数分类
    name: 'PcParameterClass',
    component: () => import('../views/pcPages/parameterClass.vue'),
    meta: { type: 'pc' }
  },
  {
    path: '/appArea', // 应用领域
    name: 'PcAppArea',
    component: () => import('../views/pcPages/appArea.vue'),
    meta: { type: 'pc' }
  },
  {
    path: '/selectedCases', // 精选案例
    name: 'PcSelectedCases',
    component: () => import('../views/pcPages/selectedCases.vue'),
    meta: { type: 'pc' }
  },
  {
    path: '/selectedCasesDetail', // 精选案例详情
    name: 'PcSelectedCasesDetail',
    component: () => import('../views/pcPages/selectedCasesDetail.vue'),
    meta: { type: 'pc' }
  },
  {
    path: '/newsInfo', // 新闻资讯
    name: 'PcNewsInfo',
    component: () => import('../views/pcPages/newsInfo.vue'),
    meta: { type: 'pc' }
  },
  {
    path: '/newsInfoDetail', // 新闻资讯详情
    name: 'PcNewsInfoDetail',
    component: () => import('../views/pcPages/newsInfoDetail.vue'),
    meta: { type: 'pc' }
  },
  {
    path: '/contactUs', // 联系我们
    name: 'PcContactUs',
    component: () => import('../views/pcPages/contactUs.vue'),
    meta: { type: 'pc' }
  }
]