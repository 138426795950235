import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/es/locale/lang/zh-cn'
import * as ElementIcons from '@element-plus/icons-vue'
import * as utils from '@/utils/index'
import * as commonData from '@/utils/data'
import VueLazyload from 'vue-lazyload'
import { Swipe, SwipeItem, Pagination, Icon, Checkbox } from 'vant'
import 'vant/lib/index.css'

// import './utils/pxtorem'

import '@/assets/styles/index.scss' // global css
const app = createApp(App)

app.config.globalProperties.$utils = utils
app.config.globalProperties.$commonData = commonData

// 全局组件
import MobileTopNav from '@/components/mobileComponents/mobileTopNav.vue'
import MobileTabs from '@/components/mobileComponents/mobileTabs.vue'
import MobileBottom from '@/components/mobileComponents/mobileBottom.vue'
import PcTopNav from '@/components/pcComponents/pcTopNav.vue'
import PcTabs from '@/components/pcComponents/pcTabs.vue'
import PcBottom from '@/components/pcComponents/pcBottom.vue'
app.component('MobileTopNav', MobileTopNav)
app.component('MobileTabs', MobileTabs)
app.component('MobileBottom', MobileBottom)
app.component('PcTopNav', PcTopNav)
app.component('PcTabs', PcTabs)
app.component('PcBottom', PcBottom)

app.use(VueLazyload)
app.use(Swipe).use(SwipeItem).use(Pagination).use(Icon).use(Checkbox)

// 使用element-plus
// 使用element-plus 并且设置全局的大小
app.use(ElementPlus, {
  locale: locale,
  // 支持 large、default、small
  size: 'default'
})


// 使用element-plus的icon
for (const [key, component] of Object.entries(ElementIcons)) {
  app.component(key, component)
}

app.use(store).use(router).mount('#app')
