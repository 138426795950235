<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  
  export default {
    data() {
      return {
      }
    },
    mounted() {
      
    },
    methods: {
      
    }
  }
</script>
<style>
  #app {
    color: #333;
    box-sizing: border-box;
    font-family: HarmonyOS Sans SC;
  }
</style>
