export const menuList = [
  { name: '网站首页', path: '/index', key: 'index', hasChildMenu: true, activePath: 'index' },
  { name: '关于我们', path: '/aboutUs', key: 'aboutUs', activePath: 'aboutUs' },
  { name: '产品中心', path: '/productCenter', key: 'productCenter', hasChildMenu: true, activePath: 'product' },
  { name: '参数分类', path: '/parameterClass', key: 'parameterClass', hasChildMenu: true, activePath: 'parameter' },
  { name: '应用领域', path: '/appArea', key: 'appArea', hasChildMenu: true, activePath: 'app' },
  { name: '精选案例', path: '/selectedCases', key: 'selectedCases', activePath: 'selected' },
  { name: '新闻资讯', path: '/newsInfo', key: 'newsInfo', activePath: 'news' },
  { name: '联系我们', path: '/contactUs', key: 'contactUs', activePath: 'contact' }
]

export const productMenuList = [
  { name: '分析仪' },
  { name: '发射机' },
  { name: '多参数探头' },
  { name: '安培小时计' },
  { name: '便携式仪表' },
  { name: '气体探测器' },
  { name: '探头模拟/数字输出' },
  { name: '探头和传感器' },
  { name: '配件' }
]