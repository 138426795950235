import { createRouter, createWebHistory } from 'vue-router'
import { mobileRouter } from './mobileRouter'
import { pcRouter } from './pcRouter'
import { watch } from 'vue'

let routes = [
  {
    path: '/',
    redirect: '/index'
  }
]

// const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|iPad/i.test(navigator.userAgent)
// routes = isMobile ? mobileRouter : pcRouter

let screenWidth = document.body.clientWidth
if (screenWidth <= 758) {
  routes = [...routes, ...mobileRouter]
} else {
  routes = [...routes, ...pcRouter]
}

window.onresize = () => {
  screenWidth = document.body.clientWidth
}

watch(screenWidth, (newVal, oldVal) => {
  let timer = setTimeout(() => {
    if (newVal <= 758) {
      routes = [...routes, ...mobileRouter]
      window.location.reload()
    } else {
      routes = [...routes, ...pcRouter]
      window.location.reload()
    }
  }, 500)
  return () => {
    clearTimeout(timer)
  }
},{ deep: true })

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  next()
})

export default router
