<template>
  <div class="bottom-box">
    <div
      class="menu-item"
      v-for="(item, index) in menuList"
      :key="index"
      @click="clickMenuItem(item)"
    >
      <div class="name">{{ item.name }}</div>
      <el-icon color="#999"><ArrowRight /></el-icon>
    </div>
    <div class="company-info">
      <div class="info-item" v-for="(item, index) in companyInfo" :key="index">
        <div class="icon">
          <img class="img" :src="require('@/assets/icons/mobileIcons/' + item.img)" alt="" />
        </div>
        <div class="info">
          <div>{{ item.label }}</div>
          <div class="color-9">{{ item.value }}</div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="line-42">©Copyright 2009-2014 版权所有 美康三杉@版权声明 蜀<br/>ICP备16004478号 All rights reserved</div>
      <div class="filling">技术支持：某某科技</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'MobileBottom',

    data() {
      return {
        menuList: [],
        companyInfo: [
          { label: '联系电话', value: '18971471372', img: 'icon_bottom_phone.png' },
          { label: '电子邮件', value: 'yzmc2009@163.com', img: 'icon_bottom_emile.png' },
          { label: '公司地址', value: '武汉市武昌区杨园金域华府商业中心A座1906室', img: 'icon_bottom_address.png' }
        ]
      }
    },

    mounted() {
      this.menuList = this.$commonData.menuList
    },

    methods: {
      clickMenuItem(item) {
        this.$router.push({
          path: item.path
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  .bottom-box {
    background-color: #FFF;
    .menu-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 30px;
      padding: 40px 30px;
      border-bottom: 2px solid #E0E0E0;
      .name {
        font-size: 28px;
        font-weight: 500;
      }
    }
    .company-info {
      padding: 48px 60px;
      .info-item {
        display: flex;
        align-items: center;
        margin-bottom: 60px;
        .icon {
          width: 76px;
          height: 76px;
          margin-right: 22px;
          .img {
            width: 100%;
            height: 100%;
          }
        }
        .info {
          font-size: 28px;
          .color-9 {
            margin-top: 16px;
          }
        }
      }
    }
    .bottom {
      padding: 40px 0 32px;
      text-align: center;
      font-size: 24px;
      color: #9D9D9D;
      background-color: #f6f6f6;
      .filling {
        margin-top: 20px;
      }
    }
  }
</style>