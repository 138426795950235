import axios from 'axios'
import { ElMessage } from 'element-plus'
import errorCode from '@/utils/errorCode'
import { tansParams } from '@/utils/ruoyi'


axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // baseURL: import.meta.env.VITE_APP_BASE_API,
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 10000
})

// request拦截器
service.interceptors.request.use(config => {
  config.responseType = config.responseType || 'json'
  // 是否需要防止数据重复提交
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params)
    url = url.slice(0, -1)
    config.params = {}
    config.url = url
  }
  if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
    const requestObj = {
      url: config.url,
      data: typeof config.data === 'object' ? JSON.stringify(config.data) : (config.data || {}),
      time: new Date().getTime()
    }
    config = {
      ...config,
      ...requestObj
    }
  }
  return config
}, error => {
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  // 未设置状态码则默认成功状态
  const code = res.data.code || res.code || 200
  // 获取错误信息
  const msg = errorCode[code] || res.data.msg || res.msg || errorCode['default']
  // 二进制数据则直接返回
  if (res.request.responseType ===  'blob' || res.request.responseType ===  'arraybuffer') {
    return res.data
  }
  if (code === 500) {
    ElMessage({ message: msg, type: 'error' })
    return Promise.reject(res.data)
  } else if (code === 601) {
    ElMessage({ message: msg, type: 'warning' })
    return Promise.reject(new Error(msg))
  } else if (code !== 200) {
    ElMessage({ message: msg, type: 'error' })
    return Promise.reject('error')
  } else {
    return  Promise.resolve(res.data)
  }
},
error => {
  let { message } = error
  if (message == "Network Error") {
    message = "系统接口连接异常，请联系管理员"
  } else if (message.includes("timeout")) {
    message = "系统接口请求超时"
  } else if (message.includes("Request failed with status code")) {
    message = "系统接口请求失败，请联系管理员"
  }
  // ElMessage({ message: message, type: 'error', duration: 5 * 1000 })
  return Promise.reject(error)
})

// 返回在vue模板中的调用接口
export default {
  get: function (url, params = {}) {
    return service({
      url,
      method: 'get',
      params
    })
  },
  post: function (url, params = {}) {
    return service({
      url,
      method: 'post',
      data: params
    })
  },
  put: function (url, params = {}) {
    return service({
      url,
      method: 'put',
      params
    })
  },
  delete: function (url, params = {}) {
    return service({
      url,
      method: 'delete',
      params
    })
  }
}

// export default service
