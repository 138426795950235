<template>
  <div class="pc-bottom">
    <div class="content-box">
      <div class="common-item">
        <div class="common-title">关注我们</div>
        <div class="common-line"></div>
        <div class="code-box">
          <div class="code-item">
            <div class="code">
              <qrcode-vue :value="codeUrl" :options="options"></qrcode-vue>
            </div>
            <div class="text">微信扫一扫</div>
          </div>
        </div>
        <div class="right-line"></div>
      </div>
      <div class="common-item">
        <div class="common-title">联系我们</div>
        <div class="common-line"></div>
        <div class="contact-us">
          <div class="contact-item" v-for="(item, index) in contactConfig" :key="index">
            <div class="icon">
              <img class="icon-img" :src="item.icon" alt="" />
            </div>
            <div class="info">
              <div class="label">{{ item.label }}</div>
              <div class="value">{{ item.value }}</div>
            </div>
          </div>
        </div>
        <div class="right-line"></div>
      </div>
      <div class="common-item">
        <div class="common-title">网站导航</div>
        <div class="common-line"></div>
        <div class="web-box">
          <div class="web-item" v-for="(item, index) in menuList" :key="index" @click="clickMenuItem(item)">
            {{ item.name }}<br v-if="index === 3" />
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-box">
      <div class="copyright">©Copyright 2009-2014 版权所有 美康三杉@版权声明 蜀ICP备16004478号 All rights reserved</div>
      <div class="technology">技术支持：某某科技</div>
    </div>
  </div>
</template>

<script>
  import QrcodeVue from 'vue-qrcode'
  export default {
    name: 'PcBottom',
    components: { QrcodeVue },
    data() {
      return {
        codeConfig: [
          { code: require('@/assets/imgs/pcImgs/code.png'), text: '微信扫一扫' }
        ],
        codeUrl: 'https://yzmc.com',
        options: {
          width: 100, // 二维码宽度
          height: 100 // 二维码高度
        },
        contactConfig: [
          { label: '联系电话', value: '18971471372', icon: require('@/assets/icons/pcIcons/icon_bottom_phone.png') },
          { label: '电子邮件', value: 'yzmc2009@163.com', icon: require('@/assets/icons/pcIcons/icon_bottom_emile.png') },
          { label: '公司地址', value: '武汉市武昌区杨园金域华府商业中心A座1906室', icon: require('@/assets/icons/pcIcons/icon_bottom_address.png') }
        ],
        menuList: []
      }
    },

    mounted() {
      this.menuList = this.$commonData.menuList
    },

    methods: {
      clickMenuItem(item) {
        this.$router.push({
          path: item.path
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  .pc-bottom {
    width: 100%;
    height: 500px;
    padding: 0 160px;
    background-color: #FFF;
    box-sizing: border-box;
    overflow: hidden;
    .content-box {
      display: flex;
      justify-content: space-between;
      margin: 115px 0;
      .common-item {
        position: relative;
        &:first-child {
          width: 355px;
        }
        &:nth-child(2) {
          flex: 1;
          padding-left: 104px;
        }
        &:last-child {
          width: 524px;
          padding-left: 104px;
        }
        .common-title {
          font-size: 18px;
          font-weight: 500;
        }
        .common-line {
          width: 26px;
          height: 2px;
          margin: 14px 0 20px;
          background-color: #00A6B9;
        }
        .right-line {
          position: absolute;
          top: 45px;
          right: 0;
          width: 1px;
          height: 90px;
          background-color: #E0E0E0;
        }
        .code-box {
          display: flex;
          .code-item {
            margin-right: 30px;
            .code-img {
              width: 100px;
              height: 100px;
              margin-bottom: 5px;
            }
            .text {
              margin-left: 20px;
              font-size: 14px;
              color: #999;
            }
          }
        }
        .contact-us {
          display: flex;
          flex-wrap: wrap;
          .contact-item {
            display: flex;
            align-items: center;
            min-width: 50%;
            margin-bottom: 32px;
            .icon {
              width: 38px;
              height: 38px;
              margin-right: 10px;
              .icon-img {
                width: 100%;
                height: 100%;
              }
            }
            .info {
              font-size: 14px;
              .value {
                margin-top: 8px;
                color: #999;
              }
            }
          }
        }
        .web-box {
          display: flex;
          flex-wrap: wrap;
          padding-right: 105px;
          .web-item {
            margin-bottom: 34px;
            padding: 0 14px;
            border-right: 1px solid #E0E0E0;
            font-size: 14px;
            color: #999;
            box-sizing: border-box;
            cursor: pointer;
            &:first-child,
            &:nth-child(5) {
              padding-left: 0;
            }
            &:nth-child(4),
            &:nth-child(8) {
              padding-right: 0;
              border-right: none;
            }
          }
        }
      }
    }
    .copyright-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 90px;
      border-top: 1px solid #E0E0E0;
      .copyright {
        font-size: 12px;
        color: #9D9D9D;
      }
      .technology {
        font-size: 12px;
        color: #999;
      }
    }
  }
</style>
