<template>
  <div class="mobile-top-nav">
    <div v-if="showLeftSlot" @click="clickLogo">
      <slot name="leftSlot"></slot>
    </div>
    <div v-else class="left-box" @click="clickLogo">
      <div class="logo">
        <img class="logo-img" src="@/assets/logo.png" alt="">
      </div>
      <div class="title">{{ title }}</div>
    </div>
    <div class="menu-icon">
      <div v-if="showMenu" class="close" @click="clickCloseMenu"></div>
      <img v-else class="icon" src="@/assets/icons/mobileIcons/icon_more.png" alt="" @click="clickShowMenu">
    </div>
    <div class="right-menu" v-if="showMenu">
      <div
        class="menu-item"
        v-for="(item, index) in menuList"
        :key="index"
        @click="clickMenu(item.path)"
      >
        {{ item.name }}
        <div class="arrow-icon"></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'MobileTopNav',

    data() {
      return {
        showMenu: false,
        menuList: []
      }
    },
    props: {
      showLeftSlot: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: '武汉扬子测控'
      }
    },

    mounted() {
      this.menuList = this.$commonData.menuList
    },

    methods: {
      clickLogo() {
        this.$router.push({
          path: '/index'
        })
      },
      clickShowMenu() {
        this.showMenu = true
      },
      clickCloseMenu() {
        this.showMenu = false
      },
      clickMenu(path) {
        this.$router.push({
          path
        })
        this.showMenu = false
      }
    }
  }
</script>
<style lang="scss" scoped>
  .mobile-top-nav {
    position: relative;
    height: 108px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: #FFF;
    box-shadow: 0px 4px 8px  rgba(0, 0, 0, 0.05);
    .left-box {
      display: flex;
      align-items: center;
      .logo {
        width: 68px;
        height: 60px;
        margin-right: 20px;
        .logo-img {
          height: 100%;
        }
      }
      .title {
        font-size: 40px;
      }
    }
    .menu-icon {
      display: flex;
      align-items: center;
      height: 100%;
      .close {
        position: relative;
      }
      .close::before, .close::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 4px;
        width: 38px;
        height: 4px;
        background-color: #333;
        transform-origin: center;
        transform: translate(-50%);
      }

      .close::before {
        transform: rotate(45deg);
      }

      .close::after {
        transform: rotate(-45deg);
      }
      .icon {
        width: auto;
        height: 30px;
      }
    }
    .right-menu {
      position: absolute;
      top: 108px;
      right: 0;
      height: calc(100vh - 108px);
      padding: 60px;
      background-color: rgba(255, 255, 255, 0.9);
      z-index: 111;
      .menu-item {
        width: 240px;
        height: 110px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 28px;
        font-weight: 500;
        border-bottom: 2px solid #E0E0E0;
        .arrow-icon {
          width: 16px;
          height: 16px;
          border: 5px solid #999;
          border-left: none;
          border-bottom: none;
          border-radius: 2px;
          transform: rotate(45deg);
        }
      }
    }
  }
</style>
