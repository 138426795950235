import api from './index'

export default {
  // banner图
  fetchBannerList: (params) => api.get('/system/banner/list', params),
  // 所有内容信息
  fetchContentList: (params) => api.get('/system/content/list', params),
  // 在线留言
  sendMessage: (params) => api.post('/system/message', params),
  // 查询公司信息列表
  fetchCompanyList: (params) => api.get('/system/company/list', params),
  // 获取公司其他信息内容/新闻资讯
  fetchCompanyOther: (params) => api.get('/system/content/list', params),
  // 获取内容的详情
  fetchContentDetail: (params) => api.get('/system/content/' + params.contentId),
  // 获取产品分类列表
  fetchClassList: (params) => api.get('/system/classify/list', params),
  // 获取下级分类列表
  fetchClassifyChild: (params) => api.get('/system/classify/children/' + params.parentId),
  // 获取产品数据列表
  fetchProductList: (params) => api.get('/system/product/list', params),
  // 移动端产品列表
  fetchMProductList: (params) => api.get('/system/product/listMobile', params),
  // 获取产品详情
  fetchProductDetail: (params) => api.get('/system/product/' + params.productId),
  // 获取相关推荐产品列表
  fetchAboutProductList: (params) => api.get('/system/product/productRecommendlist/' +  params.productId),
  // 获取精选案例的tab列表
  fetchCaseTabList: (params) => api.get('/system/classify/children/' + params.parentId),
  // 获取语言和网站的列表
  fetchWebList: (params) => api.get('/system/yzconfig/list', params)
}