export const mobileRouter = [
  {
    path: '/index', // 首页
    name: 'MobileIndex',
    component: () => import('../views/mobilePages/index.vue'),
    meta: { type: 'mobile' }
  },
  {
    path: '/indexAgent', // 代理商中心
    name: 'MobileIndexAgent',
    component: () => import('../views/mobilePages/indexAgent.vue'),
    meta: { type: 'pc' }
  },
  {
    path: '/aboutUs', // 关于我们
    name: 'MobileAboutUs',
    component: () => import('../views/mobilePages/aboutUs.vue'),
    meta: { type: 'mobile' }
  },
  {
    path: '/productCenter', // 产品中心
    name: 'MobileProductCenter',
    component: () => import('../views/mobilePages/productCenter.vue'),
    meta: { type: 'mobile' },
    // children: [
    //   {
    //     path: 'productDetail', // 产品详情
    //     name: 'MobileProductDetail',
    //     component: () => import('../views/mobilePages/productDetail.vue'),
    //   }
    // ]
  },
  {
    path: '/productDetail', // 产品详情
    name: 'MobileProductDetail',
    component: () => import('../views/mobilePages/productDetail.vue'),
    meta: { type: 'mobile' }
  },
  {
    path: '/parameterClass', // 参数分类
    name: 'MobileParameterClass',
    component: () => import('../views/mobilePages/parameterClass.vue'),
    meta: { type: 'mobile' }
  },
  {
    path: '/appArea', // 应用领域
    name: 'MobileAppArea',
    component: () => import('../views/mobilePages/appArea.vue'),
    meta: { type: 'mobile' }
  },
  {
    path: '/selectedCases', // 精选案例
    name: 'MobileSelectedCases',
    component: () => import('../views/mobilePages/selectedCases.vue'),
    meta: { type: 'mobile' }
  },
  {
    path: '/selectedCasesDetail', // 精选案例详情
    name: 'MobileSelectedCasesDetail',
    component: () => import('../views/mobilePages/selectedCasesDetail.vue'),
    meta: { type: 'mobile' }
  },
  {
    path: '/newsInfo', // 新闻资讯
    name: 'MobileNewsInfo',
    component: () => import('../views/mobilePages/newsInfo.vue'),
    meta: { type: 'mobile' }
  },
  {
    path: '/newsInfoDetail', // 新闻资讯详情
    name: 'MobileNewsInfoDetail',
    component: () => import('../views/mobilePages/newsInfoDetail.vue'),
    meta: { type: 'mobile' }
  },
  {
    path: '/contactUs', // 联系我们
    name: 'MobileContactUs',
    component: () => import('../views/mobilePages/contactUs.vue'),
    meta: { type: 'mobile' }
  }
]