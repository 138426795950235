import { createStore } from 'vuex'

export default createStore({
  state: {
    productCenterChild: [],
    parameterClassChild: [],
    appAreaChild: [],
    nowClassifyId: ''
  },
  getters: {
  },
  mutations: {
    getCenterChildConfig(state, data) {
      state.productCenterChild = data
    },
    getParamsChildConfig(state, data) {
      state.parameterClassChild = data
    },
    getAppChildConfig(state, data) {
      state.appAreaChild = data
    },
    getNowClassifyId(state, data) {
      state.nowClassifyId = data
    }
  },
  actions: {
  },
  modules: {
  }
})
